module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"type":{"KaigaiFx":{"limit":9999},"MediaItem":{"localFile":{"requestConcurrency":1,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"Comment":{"exclude":true},"Menu":{"exclude":true},"PostFormat":{"exclude":true},"UserRole":{"exclude":true},"MenuItem":{"exclude":true},"Post":{"exclude":true},"Category":{"exclude":true},"Tag":{"exclude":true},"Page":{"exclude":true},"ContentType":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"debug":{"timeBuildSteps":true,"preview":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"production":{"hardCacheMediaFiles":true,"allow404Images":false,"allow401Images":false},"schema":{"timeout":1800000,"requestConcurrency":20,"perPage":20,"previewRequestConcurrency":2,"queryDepth":10,"circularQueryLimit":1,"typePrefix":"Wp"},"html":{"useGatsbyImage":false,"createStaticFiles":false,"imageQuality":70,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"url":"https://hqwcebezyauv5dzpvipkc1cdh.atoz-media.co.jp/?SyR8U9twW6m5=true","verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N8P7FD6","includeInDevelopment":false,"insertDataLayerOnInit":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
